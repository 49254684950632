<template>
	<div>
		<h3 class="marginB20">公众号推广数据分析</h3>
		<el-card class="marginB20">
			<h4 class="marginR10 marginB20">推广数据</h4>
			<div class="flexA color666">
				<div class="marginR60">
					<div>关注总人数</div>
					<div>{{dataInfo.subscribeQuantity}}</div>
				</div>
				<div class="marginR60">
					<div>取消关注人数</div>
					<div>{{dataInfo.unsubscribeQuantity}}</div>
				</div>
				<div>
					<div>访问小程序人数</div>
					<div>{{dataInfo.registerQuantity}}</div>
				</div>
			</div>
		</el-card>
		<div>
			<div class="flexA marginB20">
				<h3 class="marginR10">推广数据统计</h3>
				<div class="flexA marginR10">
					<el-date-picker class="marginR10" v-model="timeInterval" @change="search()" type="daterange" :disabled-date="disabledDate" value-format="YYYY-MM-DD" start-placeholder="开始日期"
						end-placeholder="结束日期" :clearable="false" />
					<el-input placeholder="推广码名称" v-model="pushData.spreadQrcodeName" @keyup.enter="search()" style="width: 200px;">
						<template #suffix>
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search()"></component>
							</el-icon>
						</template>
					</el-input>
				</div>
			</div>
			<div ref="echartsContainer" id="echartsContainer" style='height: 300px;width: 100%'></div>
		</div>
		<h3 class="marginR10">推广排行</h3>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 10px;">
			<el-table-column prop="ranking" label="排名" min-width="70" />
			<el-table-column prop="spreadQrcodeName" label="推广码名称" min-width="100" />
		    <el-table-column prop="shopName" label="门店名称" min-width="90" />
			<el-table-column prop="subscribeQuantity" label="关注数量" min-width="90" />
			<el-table-column prop="unsubscribeQuantity" label="取关数量" min-width="90" />
		</el-table>
	</div>
</template>

<script>
	import {spreadQrcodeDataChartAnalyse,spreadQrcodeDataStatistics} from '@/api'
	import * as echarts from 'echarts'
	// require(['echarts/theme/macarons'])
	import {dateFormat} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				dataInfo:{
					subscribeQuantity:0,
					unsubscribeQuantity:0,
					registerQuantity:0
				},
				pushData:{},
				timeInterval:[],
				tableData:[],
				emptyText:'暂无数据'
			}
		},
		computed:{
			// 计算出本月第一天和最后一天
			countDate () {
			  let end = new Date()
			  let start = new Date()
			  start = new Date(start.getFullYear(), start.getMonth(), 1)
			  return [
			    dateFormat(start, 'YYYY-MM-DD'),
			    dateFormat(end, 'YYYY-MM-DD')
			  ]
			}
		},
		created() {
			this.timeInterval = this.countDate
			this.search()
			this.getData()
			
		},
		methods:{
			getData(){
				spreadQrcodeDataStatistics().then(res => {
					if(res&&res.success){
						let data = res.data
						this.dataInfo = {
							subscribeQuantity:data.subscribeQuantity?data.subscribeQuantity:0,
							unsubscribeQuantity:data.unsubscribeQuantity?data.unsubscribeQuantity:0,
							registerQuantity:data.registerQuantity?data.registerQuantity:0
						}
						this.tableData = data.ranking
					}
				})
			},
			init(){
				// console.log(this.$refs.echartsContainer)
				// 基于准备好的dom，初始化echarts实例
				var myChart = echarts.init(this.$refs.echartsContainer);
				// // 绘制图表
				myChart.setOption({
					title: {},
					tooltip: {},
					legend: {
					    data:['已关注','取消关注']
					},
					grid: {x: 60, x2: 20},
					toolbox: {
					  show: false,
					  feature: {
					    mark: { show: true },
					    dataView: { show: true, readOnly: false },
					    magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
					    restore: { show: true },
					    saveAsImage: { show: true }
					  }
					},
					calculable: true,
					xAxis: {
						data:this.daysArr
						// data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
					},
					yAxis: {},
					series: [
						{
							name:'已关注',
							type:'line',
							data:this.subscribeArr
							// data:[120, 132, 101, 134, 90, 230]
						},
						{
							name: '取消关注',
							type: 'line',
							data:this.unsubscribeArr
							// data: [5, 20, 36, 10, 10, 20]
						}
					]
				});
			},
			search(){
				// console.log(this.timeInterval)
				// if(!this.timeInterval){
				// 	this.timeInterval = this.countDate
				// }
				let postData = {}
				if(this.timeInterval&&this.timeInterval.length>0){
					postData.beginTime = new Date(this.timeInterval[0]+' '+'00:00:00').getTime()/1000
					postData.endTime = new Date(this.timeInterval[1]+' '+'23:59:59').getTime()/1000
				}else{
					postData.beginTime = null
					postData.endTime = null
				}
				postData = Object.assign(postData, this.pushData)
				if(!postData.state){
					delete postData.state
				}
				spreadQrcodeDataChartAnalyse(postData).then(res => {
					if(res&&res.success){
						let list = res.data
						let subscribeArr = []
						let unsubscribeArr = []
						let daysArr = []
						list.forEach(item=>{
							subscribeArr.push(item.subscribeQuantity)
							unsubscribeArr.push(item.unsubscribeQuantity)
							daysArr.push(item.date)
						})
						this.subscribeArr = subscribeArr
						this.unsubscribeArr = unsubscribeArr
						this.daysArr = daysArr
						this.init()
						// this.tableData = list
					}
				})
			},
			disabledDate(time) {
				let start = new Date()
				start = new Date(start.getFullYear(), start.getMonth(), 1)
				let begin = dateFormat(start, 'YYYY-MM-DD')
				let beginTime = new Date(begin+' '+'00:00:00').getTime()
				// return (time.getTime() > Date.now())||(beginTime > time.getTime())
				return time.getTime() > Date.now()
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	.marginR60{margin-right: 60px;}
	.color666{color: #666;}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>