<template>
	<div>
		<div class="flexB">
			<div class="flexA marginR10">
				<el-input placeholder="推广码名称" v-model="pushData.fuzzySearch" @keyup.enter="search()" style="width: 200px;">
					<template #suffix>
						<el-icon class="el-input__icon">
							<component :is="Search" class="enter-sj" @click="search()"></component>
						</el-icon>
					</template>
				</el-input>
			</div>
			<el-button type="primary" color='#112DF2' @click="add">新增推广码</el-button>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 10px;">
			<el-table-column prop="name" label="推广码名称" min-width="100" />
			<el-table-column prop="spreadChannelName" label="推广渠道" min-width="90" />
			<el-table-column prop="url" label="推广码" min-width="90">
				<template #default="{row}">
					<!-- <img class="qrcode" style="width: 60px;margin: 0 auto;" :src="row.url" alt=""> -->
					<vue-qrcode class="qrcode" :value="row.url" style="width: 60px;margin: 0 auto;" fg-color="#000" bg-color="#fff"></vue-qrcode>
				</template>
			</el-table-column>
			<el-table-column prop="isShop" label="是否门店" min-width="90" />
			<el-table-column prop="shopName" label="门店名称" min-width="90" />
			<el-table-column prop="spreadDataQuantity" label="推广数据" min-width="90">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="viewPro(row.id)">{{row.spreadDataQuantity}}</el-button>
				</template>
			</el-table-column>
		    <el-table-column prop="enabled" label="推广状态" min-width="90">
				<template #default="{row}">
					<el-switch
					  @change="inOrOff(row.enabled,row.id)"
					  v-model="row.enabled"
					  style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
					  :active-value="false" :inactive-value="true"/>
				</template>
			</el-table-column>
			<el-table-column prop="createdTime" label="创建时间" min-width="160" />
			<el-table-column prop="createdTime" label="操作" min-width="120">
				<template #default="scope">
					<el-button link type="primary" plain color="#112DF2" @click="edit(scope.row.id,1)">编辑</el-button>
					<!-- <el-button link type="primary" plain color="#112DF2" @click="edit(scope.row.id,2)">推广分析</el-button> -->
					<el-button link type="primary" plain color="#112DF2" @click="downQrCode(scope.row.id,scope.row.url,scope.$index,scope.row.name)">下载</el-button>
					<!-- <el-button v-if="scope.row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(scope.row.id,0)">关闭</el-button>
					<el-button v-if="!scope.row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(scope.row.id,1)">正常</el-button> -->
				</template>
			</el-table-column>
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {spreadQrcodePageList,spreadQrcodeUpdateEnabledById} from '@/api'
	import {formatDateOrder} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				pushData:{
					fuzzySearch:''
				},
				tableData:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		created() {
			this.search()
		},	
		methods:{
			search(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				// return
				postData = Object.assign(postData, this.pushData)
				spreadQrcodePageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
							item.isShop = item.isShop?'是':'否'
							// item.enabled = item.enabled?1:0
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			inOrOff(enabled,id){
				spreadQrcodeUpdateEnabledById({data:{id,enabled}}).then(res=>{
					if(res.success){
						this.search()
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			add(){
				this.$router.push('/distributionManagement/officialAccountsCreat/')
			},
			edit(id,type){
				if(type==1){
					this.$router.push('/distributionManagement/officialAccountsCreat/'+id)
					return
				}
				this.$router.push('/distributionManagement/officialAccountsDataAnalysis/'+id)
			},
			viewPro(id){
				this.$router.push('/distributionManagement/officialAccountsData/'+id)
			},
			downQrCode(id,href,index,name){
				let canvasData = document.getElementsByClassName('qrcode')
				const imgFile = this.base64ToFile(canvasData[index].src,name);
				let imgDown= document.createElement('a');
				imgDown.download = name;
				imgDown.href = URL.createObjectURL(imgFile);
				// imgDown.href = href
				imgDown.click();
			},
			base64ToFile(base64, fileName) {
				let arr = base64.split(",");
				let mime = arr[0].match(/:(.*?);/)[1];
				let bytes = atob(arr[1]);
				let n = bytes.length;
				let ia = new Uint8Array(n);
				while (n--) {
					ia[n] = bytes.charCodeAt(n);
				}
				return new File([ia], fileName, { type: mime });  // 将值抛出去
			},
			groupPrice(index,val){
				if(val>99999.99)val=99999.99;
				if(val<0)val=0;
				this.tableData[index].spreadUnitPrice = val
			},
			groupPriceBlur(index,val){
				val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
				this.tableData[index].spreadUnitPrice = val
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>