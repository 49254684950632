<template>
	<div style="width: 70%;">
		<div class="flexB marginB80">
			<h3>{{edit?'编辑':'新增'}}推广码</h3>
			<div style="margin-right: 200px;">
				<el-button class="marginR10" type="primary" color="#112DF2" @click="save">保存{{edit?'修改':'新增'}}</el-button>
				<el-button type="primary" color="#787878" @click="() => this.$router.go(-1)">暂不保存</el-button>
			</div>
		</div>
		<div style="margin-left: 100px;">
			<el-form :model="pushData" ref="formBox" :rules="rules" label-width="180px" class="formBox">
				<el-form-item style="margin-bottom: 30px;" label="名称:" prop="name">
					<el-input v-model="pushData.name" maxlength="30" placeholder="输入名称" clearable style="width: 400px;"></el-input>
				</el-form-item>
				<el-form-item style="margin-bottom: 30px;" label="推广渠道:" prop="spreadChannelId">
					<el-select v-model="pushData.spreadChannelId" placeholder="请选择推广渠道" :disabled='edit' clearable style="width: 400px;">
						<el-option v-for="item in channelList" :key="item.id" style="max-width: 400px;"
							:label='item.name' :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="margin-bottom: 30px;" label="推广码类型:" prop="codeType">
					<el-select v-model="pushData.type" placeholder="请选择推广码类型" :disabled='edit' style="width: 400px;">
						<el-option v-for="item in codeTypeList" :key="item.id" style="max-width: 400px;"
							:label='item.name' :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="margin-bottom: 30px;" label="是否门店:" prop="isShop">
					<el-radio-group v-model="pushData.isShop" :disabled='edit'>
						<el-radio label="true">是</el-radio>
						<el-radio label="false">否</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item style="margin-bottom: 30px;" v-if="pushData.isShop=='true'" label="门店名称:" prop="shopId">
					<el-select v-model="pushData.shopId" placeholder="请选择门店名称" :disabled='edit' clearable style="width: 400px;">
						<el-option v-for="item in shopList" :key="item.id" :disabled="item.disabled" style="max-width: 400px;"
							:label='item.name' :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="margin-bottom: 30px;" label="推广状态:" prop="enabled">
					<el-switch style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#409eff;"
					 v-model="pushData.enabled" :active-value="false" :inactive-value="true"></el-switch>
				</el-form-item>
				<el-form-item style="margin-bottom: 30px;" class="formItems" label="备注:" prop="remark">
					<el-input type="textarea" maxlength="50" v-model="pushData.remark" resize="none" rows="5" clearable style="width: 400px;"></el-input>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {spreadQrcodeCreate,spreadQrcodeUpdateGetById,shopList,spreadChannelList,spreadQrcodeUpdateById} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	export default {
		data () {
			return {
				pushData:{
					shopId:'',
					spreadChannelId:'',
					isShop:'true',
					enabled:true,
					type:'WECHAT_MP',
					remark:''
				},
				edit: false,
				channelList:[],
				codeTypeList:[{id:'WECHAT_MP',name:'公众号码'}],
				shopList:[],
				rules: {
					name:[{required: true,message: '请输入名称', trigger: 'blur'}],
					// shopId: [{required: true,message: '请选择门店名称', trigger: 'blur'}],
					spreadChannelId: [{required: true,message: '请选择推广渠道',trigger: 'blur'}]
				},
			}
		},
		created() {
			this.getSpreadChannelList()
			this.getShopList()
			if(this.$route.params.id){
				this.getData(this.$route.params.id)
				this.edit = true
			}
		},	
		methods:{
			getData(id){
				spreadQrcodeUpdateGetById({data:{id}}).then(res => {
					if(res&&res.success){
						let data = res.data
						data.isShop = data.isShop.toString()
						this.pushData = data
					}
				})
			},
			getSpreadChannelList(){
				spreadChannelList().then(res => {
					if(res&&res.success){
						this.channelList = res.data
					}
				})
			},
			getShopList(){
				let postData = {
					enabled:true
				}
				shopList(postData).then(res => {
					if(res&&res.success){
						let data = res.data
						this.shopList = data
					}
				})
			},
			save(){
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						let urlPost = this.edit ? spreadQrcodeUpdateById : spreadQrcodeCreate
						let pushData = this.pushData
						let body = pushData
						if(pushData.isShop == 'false'){
							delete body.shopId
						}
						if(this.edit){
							body = {
								id:this.$route.params.id,
								name:pushData.name,
								enabled:pushData.enabled?true:false,
								remark:pushData.remark
							}
						}
						console.log(body)
						urlPost(body).then(res=>{
							if(res&&res.success){
								this.$message({
									showClose: true,
									message: "操作成功",
									type: "success",
								});
								setTimeout(()=>{
									this.$router.replace('/distributionManagement/officialAccountsCodeList')
								},1000)
							}else{
								this.$message.error(res.msg)
							}
						})
					}
				})
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>